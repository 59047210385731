import { useContext, useState } from 'react';
import './cadastro.css';
import { useLocation, useNavigate } from 'react-router-dom';

import iconeBordo from '../../assets/images/iconeBordo.png';

import InputText from '../../components/inputText';
import InputSenha from '../../components/inputSenha';
import Botao from '../../components/botao';

import { IsEmail, IsSenhaForte } from '../../services/validadores';

import AuthContext from '../../context/auth';
import { criarUsuarioDB } from '../../services/authFirestore';

function Cadastro() {

    const navigate = useNavigate();
    let location = useLocation();

    const { cadastroUsuario, excluirUsuario } = useContext(AuthContext);

    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmacaoSenha, setConfirmacaoSenha] = useState("");
    const [msgErroPreenchimento, setMsgErroPreenchimento] = useState("");
    const [necessidadesSenha, setNecessidadesSenha] = useState({});

    const handleNome = (event) => {
        setNome(event.target.value);
    }

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handleSenha = (event) => {
        setSenha(event.target.value);

        let responseSenha = IsSenhaForte(event.target.value);

        if(!responseSenha.sucesso){
            setNecessidadesSenha(responseSenha);
            return;
        }

    }

    const handleConfirmacaoSenha = (event) => {
        setConfirmacaoSenha(event.target.value);
    }

    const cadastrarUsuario = async () => {
        setMsgErroPreenchimento("");

        if(!IsEmail(email)){
            setMsgErroPreenchimento("E-mail inválido.");
            return;
        }

        if(senha != confirmacaoSenha){
            setMsgErroPreenchimento("Senhas diferentes.");
            return;
        }

        if(
            !necessidadesSenha?.digito ||
            !necessidadesSenha?.minuscula ||
            !necessidadesSenha?.maiuscula ||
            !necessidadesSenha?.oitoCaracteres
        ){ return; }

        const response = await cadastroUsuario(email, senha, nome);

        if(!response.success){
            if(response.errorCode == "auth/email-already-exists" || response.errorCode == "auth/email-already-in-use"){
                console.log("E-mail já cadastrado.")
                setMsgErroPreenchimento("E-mail já cadastrado.");
            }
            return;
        }

        const responseDB = await criarUsuarioDB({
            nome: nome,
            email: email,
            id: response.data.uid
        });

        if(!responseDB.success){
            excluirUsuario();
            return;
        }

        if(location?.state){
            navigate('/', { state: location?.state })
            return;
        }

        navigate('/');

    }

    return (
        <div className='cadastroDiv'>
            {/* <div className='box' style={{backgroundColor: "#1E1F20"}}>
                <text>Olá</text>
            </div> */}
            <div className='box'>
                <img 
                    className="imagemIcone"
                    src={iconeBordo} 
                    alt="Icone"
                />
                <InputText value={nome} onChange={handleNome} placeholder={"Nome completo"} />
                <InputText value={email} onChange={handleEmail} placeholder={"E-mail"} />
                <InputSenha value={senha} onChange={handleSenha} placeholder={"Senha"}/>
                <InputSenha value={confirmacaoSenha} onChange={handleConfirmacaoSenha} placeholder={"Confirmação de senha"}/>
                {senha.length > 0 &&
                    <>
                    {!necessidadesSenha?.digito &&
                        <div className={"txtPreenchimentoIncorreto"}>
                            <text>Senha deve conter ao menos um número.</text>
                        </div>
                    }
                    {!necessidadesSenha?.minuscula &&
                        <div className={"txtPreenchimentoIncorreto"}>
                            <text>Senha deve conter ao menos uma letra Minúscula.</text>
                        </div>
                    }
                    {!necessidadesSenha?.maiuscula &&
                        <div className={"txtPreenchimentoIncorreto"}>
                            <text>Senha deve conter ao menos uma letra Maiúscula.</text>
                        </div>
                    }
                    {!necessidadesSenha?.oitoCaracteres &&
                        <div className={"txtPreenchimentoIncorreto"}>
                            <text>Senha deve conter ao menos 8 caracteres.</text>
                        </div>
                    }
                    </>
                }
                <div className="txtPreenchimentoIncorreto">
                    <text>{msgErroPreenchimento}</text>
                </div>
                <Botao
                    colorButton={"#1E1F20"}
                    onClick = {cadastrarUsuario}
                    textoBotao = {'Cadastrar'}
                />
                <div className="txtAbaixoBotoes" onClick={() => navigate('../login')}>
                    <text>Já tem uma conta ? Entre agora!</text>
                </div>
            </div>
        </div>
    );
}

export default Cadastro;
