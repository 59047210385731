import React, { useState } from 'react';
import './modal.css';

import InputText from '../inputText';
import BotaoLogo from '../botaoLogo';
import { editaUsuarioDB } from '../../services/authFirestore';
import { useEffect } from 'react';

export default function Modal (params) {

    const [cep, setCEP] = useState("");
    const [endereco, setEndereco] = useState("");
    const [avisaNumero, setAvisaNumero] = useState(false);
    const [avisaCEP, setAvisaCEP] = useState(false);

    useEffect(() => {
        if(cep.length == 8){
            pesquisaCEP();
        }
    },[cep])

    const handleCEP = (event) => {
        if(event.target.value.length >= 9){
            return;
        }
        setCEP(event.target.value);
    }

    const handleNumero = (event) => {

        if(!cep){
            alert("Preencha o CEP primeiramente!")
            setEndereco({
                ...endereco,
                numero: ""
            })
            return;
        }

        setEndereco({
            ...endereco,
            numero: event.target.value
        })
    }

    const handleComplemento = (event) => {

        if(!cep){
            alert("Preencha o CEP primeiramente!")
            setEndereco({
                ...endereco,
                complemento: ""
            })
            return;
        }

        setEndereco({
            ...endereco,
            complemento: event.target.value
        })
    }

    const pesquisaCEP = () => {
        console.log("pesquisaCEP")
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => {
          response.json()
            .then(data => {
                
                if(data?.erro){
                    setEndereco({
                        ...endereco,
                        complemento: "",
                        numero: ""
                    })
                    alert("CEP não encontrado!");
                    return;
                }

                setEndereco({
                    ...data,
                    complemento: "",
                    numero: ""
                });
            })
        }).catch( err => {
            console.log("Error - ", err)
            alert("CEP não encontrado!");
        })
    }

    const salvarEndereco = async () => {
        setAvisaNumero(false);
        setAvisaCEP(false);

        if(!endereco.numero){
            setAvisaNumero(true);
            return;
        }

        if(!endereco?.logradouro){
            setAvisaCEP(true);
            return;
        }

        const responseEditaUsuario = await editaUsuarioDB(params.idUsuario, {enderecoEntrega: endereco});

        if(!responseEditaUsuario.success){
            alert("Endereço não foi cadastrado! Tente novamente...")
            return;
        }

        params.onClick();
    }

    return(
        <div className='containerModal'>
            <div className='modal'>
                <div style={{justifyContent: "space-between", display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10}}>
                    <h4>Cadastro endereço de entrega</h4>
                    <h2 onClick={() => params.onClick()} style={{color: "grey", cursor: "pointer"}}>x</h2>
                </div>
                <div className='containerRow'>
                    <InputText value={cep} onChange={handleCEP} placeholder={"CEP"} style={{width: 260, marginBottom: 0, 
                        borderBottomWidth: avisaCEP ? 3 : 0,
                        borderBottomColor: 'red',
                        borderEndEndRadius: avisaCEP && 0,
                        borderEndStartRadius: avisaCEP && 0}}/>
                    <BotaoLogo
                        style={{width: 50, height: 50}}
                        onClick={pesquisaCEP}
                        pesquisa={true}
                    />
                </div>
                <div className='containerRow'>
                    <InputText value={endereco?.numero} onChange={handleNumero} placeholder={"Número"} style={{width: 150, marginBottom: 10, 
                        borderBottomWidth: avisaNumero ? 3 : 0,
                        borderBottomColor: 'red',
                        borderEndEndRadius: avisaNumero && 0,
                        borderEndStartRadius: avisaNumero && 0
                    }}/>
                    <InputText value={endereco?.complemento} onChange={handleComplemento} placeholder={"Complemento"} style={{width: 150, marginBottom: 10}}/>
                </div>
                {(cep && endereco?.logradouro) && 
                    <h4 style={{marginBottom: 10}}>
                        {endereco?.logradouro+", "+ (endereco?.numero ? endereco?.numero+(endereco?.complemento ? " - ": ", ") : "") + (endereco?.complemento ? endereco?.complemento+", " : "") + endereco?.bairro+" - "+endereco?.localidade+" - "+endereco?.uf+" - "+endereco?.cep}
                    </h4>
                }
                <div 
                    style={{backgroundColor: "#690B23", width: 310, alignSelf: 'center'}}
                    className='containerBotao' 
                    onClick={salvarEndereco}
                >
                    <text style={{backgroundColor: "#690B23"}} className='txtBotao'>Cadastrar!</text>
                </div>
            </div>
        </div>
    )
}