import { app } from "./firebase";
import {
    getFirestore,
    collection,
    addDoc,
    doc,
    deleteDoc,
    getDocs,
    updateDoc,
    getDoc,
    setDoc,
    query, 
    where
} from "firebase/firestore";

const db = getFirestore(app);

export const criarUsuarioDB = async (customers) => {

    let response = {};

    try {
        await setDoc(doc(db, "customers", customers.id), {
            nome: customers.nome,
            dataCadastro: new Date()
        });
    
        response =  {
            success: true
        };
    } catch (err) {
        console.error("criarUsuarioDB - ", err);

        response =  {
            success: false,
            errorMessage: err
        };
    }

    return response;
}

export const pegarUsuarioDB = async (id) => {

    let response = {};

    try {

        const docSnap = await getDoc(doc(db, "customers", id));

        if (docSnap.exists()) {
    
            response =  {
                success: true,
                data: docSnap.data(),
                msg: "Dado encontrado!"
            };
            return response;
        }

        response =  {
            success: true,
            data: [],
            msg: "Nenhum dado encontrado!"
        };

    } catch (err) {
        console.error("pegarUsuarioDB - ", err);

        response =  {
            success: false,
            errorMessage: err
        };
    }

    return response;
}

export const editaUsuarioDB = async (idUsuario ,dados) => {

    let response = {};

    try {

        const usuarioEditando = doc(db, "customers", idUsuario);
        await updateDoc(usuarioEditando, dados, {
            merge: true
        });
    
        response =  {
            success: true
        };
    } catch (err) {
        console.error("editaUsuarioDB - ", err);

        response =  {
            success: false,
            errorMessage: err
        };
    }

    return response;
}




