import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './cancelandoAssinatura.module.css';

import iconePreto from '../../assets/images/iconePreto.png';

import BotaoCancelar from '../../components/botaoCancelar';
import TextoMeses from '../../components/textoMeses';
import { getPortalUrl } from '../../services/startSubscription';

function CancelandoAssinatura() {

    const navigate = useNavigate();

    return (
        <body className={styles.bodyCancelando}>
            <img 
                className={styles.imagemIcone}
                src={iconePreto} 
                alt="Icone"
            />
            <div>
                <h3>Sommelie Iniciante</h3>
                <h2>Helen Reis da Costa</h2>
            </div>

            <div>
                <h1>Adeus, então...</h1>
                <h1>Contamos com a saudade.</h1>
            </div>
            <div className={styles.linha}>
                <div style={{width: '40%', paddingLeft: 20}}>
                    <BotaoCancelar
                        onClick={async () => {
                            const portalUrl = await getPortalUrl()
                            window.location = portalUrl;
                        }}
                        textBotao={"Confirmar cancelamento"}
                    />
                </div>
                <div style={{width: '40%'}}>
                    <BotaoCancelar
                        onClick={() => {navigate("/")}}
                        botaoVerde={true}
                        textBotao={"Manter assinatura"}
                    />
                </div>
                <div style={{width: '20%', paddingRight: 20}}>
                    {/* <TextoMeses
                        recebidos={"10"}
                        mesesConosco={"10"}
                        mesesConsecutivos={"10"}
                    /> */}
                </div>
            </div>
        </body>
    );
}

export default CancelandoAssinatura;
