import React, { createContext, useState } from "react";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, onAuthStateChanged, signOut, deleteUser} from "firebase/auth";
import { app } from "../services/firebase";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [validaUsuarioLogado, setValidaUsuarioLogado] = useState(true);
    const auth = getAuth();

    onAuthStateChanged(auth, (userFirebase) => {
        if (userFirebase && !user) {
            setUser(userFirebase)
        }
        setValidaUsuarioLogado(false);
    });

    const logarUsuario = async (email, senha) => {

        let response = {};
        await signInWithEmailAndPassword(auth, email, senha)
        .then((userCredential) => {
            
            const user = userCredential.user;
            setUser(user);
            response =  {
                success: true
            };
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.error("logarUsuario - ", errorCode, " - ", errorMessage);
            response =  {
                success: false,
                errorCode,
                errorMessage
            };
        });

        return response;
    }

    const logOut = async () => {
        
        auth.signOut().then(function () {
            setUser(null);
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            console.error("logOut - ", errorCode, " - ", errorMessage);
        });
        

    }

    const cadastroUsuario = async (email, senha, nomeCompleto) => {

        let response = {};
        // const auth = await getAuth();
        await createUserWithEmailAndPassword(auth, email, senha)
          .then((userCredential) => {

            const user = userCredential.user;
            updateProfile(auth.currentUser, {
                displayName: nomeCompleto
            });

            // Signed up 
            setUser(user);
            response =  {
                success: true,
                data: userCredential.user
            };
          })
          .catch((err) => {
            const errorCode = err.code;
            const errorMessage = err.message;
            
            console.error("cadastroUsuario - ", errorCode, " - ", errorMessage);
            response =  {
                success: false,
                errorCode,
                errorMessage
            };
          });

          return response;
    }

    const excluirUsuario = async () => {

        let response = {};

        const auth = getAuth();
        const userAutenticado = auth.currentUser;

        await deleteUser(userAutenticado).then(() => {
            setUser(null);

            response =  {
                success: true
            };
        }).catch((err) => {
            const errorCode = err.code;
            const errorMessage = err.message;
            
            console.error("excluirUsuario - ", errorCode, " - ", errorMessage);
            response =  {
                success: false,
                errorCode,
                errorMessage
            };
        });

        return response;
    }


    return (
        <AuthContext.Provider value={{ signed: !!user, logarUsuario, logOut, cadastroUsuario, excluirUsuario, user: user, validaUsuarioLogado }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;