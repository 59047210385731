import { ThreeDot } from 'react-loading-indicators';
import styles from './botaoDourado.module.css';

export default function botaoDourado(params) {
    return(
        <div 
            className={styles.containerBotao} 
            style={{
                background: "linear-gradient(90deg, rgba(255,255,149,1) 0%, rgba(242,193,41,1) 50%,rgba(221,172,23,1) 75%, rgba(255,255,149,1) 100%)"
            }}  
            onClick={() => {
                params.onClick();
            }}
        >
            {params.carregando ?
                <ThreeDot variant="bounce" color="#ffffff" size="small" text="" textColor="" />
            :
            <>
                <text className={styles.txtBotao}>{params.textoBotao}</text>
                <p className={styles.txtBotao} style={{fontSize: 16, paddingTop: 8}}>{params.valor}</p>
            </>
            }
        </div>
    )
}