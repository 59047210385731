import { ThreeDot } from 'react-loading-indicators';

import styles from './apenasIcone.module.css';

import iconePreto from '../../assets/images/iconeBordo.png';

function ApenasIcone() {
    return (
        <body className={styles.bodyCancelando}>
            <img 
                className={styles.imagemIcone}
                src={iconePreto} 
                alt="Icone"
            />
            <ThreeDot variant="bounce" color="#ffffff" size="small" text="" textColor="" />
        </body>
    );
}

export default ApenasIcone;
