import './botaoLogo.css';
import { FaRegUser } from "react-icons/fa";
import { IoIosPin } from "react-icons/io";

export default function botaoLogo(params) {
    return(
        <div 
            {...params}
            className='containerBotaoLogo' 
            onClick={() => {
                params.onClick();
            }}
        >
            {params.pesquisa ?
                <IoIosPin 
                    color='#FFF'
                    size={30}
                />
            :
                <FaRegUser 
                    color='#FFF'
                    size={20}
                />
            }
        </div>
    )
}