import React, { useContext } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import AuthContext from '../context/auth';

import Apresentacao from "../pages/apresentacao";
import Login from "../pages/login";
import Cadastro from "../pages/cadastro";
import Menu from "../pages/menu";
import CancelandoAssinatura from "../pages/cancelandoAssinatura";
import AssinaturaCadastrada from "../pages/assinaturaCadastrada";
import ApenasIcone from "../pages/apenasIcone";

const AppRoutes = () => {
    const { signed, validaUsuarioLogado } = useContext(AuthContext);

    if(validaUsuarioLogado){
        return(
            <Router>
                <Routes>
                    <Route path="*" element = {<ApenasIcone/>} />
                </Routes>
            </Router>
        )
    }

    return(
       <Router>
            {!signed ? 
                <Routes>
                    <Route path="*" element = { <Apresentacao/> } />
                    <Route path="/login" element = { <Login/> } />
                    <Route path="/cadastro" element = { <Cadastro/> } />
                </Routes>
                :
                <Routes>
                    <Route path="*" element = { <Menu/> } />
                    <Route path="/cancelandoAssinatura" element = { <CancelandoAssinatura/> } />
                    <Route path="/assinaturaCadastrada" element = { <AssinaturaCadastrada/> } />
                </Routes>
            }
       </Router>
   )
}

export default AppRoutes;