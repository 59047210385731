import { useContext, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import './login.css';

import iconeBordo from '../../assets/images/iconeBordo.png';

import InputText from '../../components/inputText';
import InputSenha from '../../components/inputSenha';
import Botao from '../../components/botao';

import AuthContext from '../../context/auth';

function Login() {

    const { logarUsuario } = useContext(AuthContext);
    const navigate = useNavigate();
    let location = useLocation();

    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [msgErroPreenchimento, setMsgErroPreenchimento] = useState("");

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handleSenha = (event) => {
        setSenha(event.target.value);
    }

    const enviaDadosLogin = async () => {
        const response = await logarUsuario(email, senha);

        if(!response.success){
            if(response.errorCode == "auth/invalid-email"){
                setMsgErroPreenchimento("E-mail inválido.");
            }

            if(response.errorCode == "auth/missing-password"){
                setMsgErroPreenchimento("Senha inválida.");
            }

            if(response.errorCode == "auth/user-not-found"){
                setMsgErroPreenchimento("Usuário não encontrado.");
            }
            
            if(response.errorCode == "auth/invalid-credential"){
                setMsgErroPreenchimento("E-mail ou senha inválida.");
            }
            return;
        }

        if(location?.state){
            navigate('/', { state: location.state})
            return;
        }

        navigate('/')
    }

    return (
        <div className='loginDiv'>
            {/* <div className='box' style={{backgroundColor: "#1E1F20"}}>
                <text>Olá</text>
            </div> */}
            <div className='box'>
                <img 
                    className="imagemIcone"
                    src={iconeBordo} 
                    alt="Icone"
                />
                <InputText value={email} onChange={handleEmail} placeholder={"E-mail"} />
                <InputSenha value={senha} onChange={handleSenha} placeholder={"Senha"}/>
                <div className="txtPreenchimentoIncorreto">
                    <text>{msgErroPreenchimento}</text>
                </div>
                <Botao
                    colorButton={"#1E1F20"}
                    onClick = {enviaDadosLogin}
                    textoBotao = {'Entrar'}
                />
                <div className="txtAbaixoBotoes" onClick={() => {
                    
                    if(location?.state){
                        navigate('../cadastro', { state: location.state})
                        return;
                    }

                    navigate('../cadastro')
                }}>
                    <text>Ainda não tem uma conta ? Crie agora!</text>
                </div>
            </div>
        </div>
    );
}

export default Login;
