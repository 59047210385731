import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdEdit } from "react-icons/md";

import iconePreto from '../../assets/images/iconePreto.png';

import BotaoSair from '../../components/botaoSair';
import BotaoDourado from '../../components/botaoDourado';
import BotaoCancelar from '../../components/botaoCancelar';
import TextoMeses from '../../components/textoMeses';
import Modal from '../../components/modal';

import styles from './menu.module.css'

import AuthContext from '../../context/auth';

import { pegarUsuarioDB } from '../../services/authFirestore';
import { getCheckoutUrl, getPortalUrl, listaProdutos, verificaAssinaturaUsuario } from '../../services/startSubscription';

function Menu() {

    const { logOut, user } = useContext(AuthContext);

    const [nivelAssinaturaUsuario, setNivelAssinaturaUsuario] = useState("");
    const [mostraModalCancelar, setMostraModalCancelar] = useState(false);
    const [dadosEndereco, setDadosEndereco] = useState({});
    const [carregandoBotao, setCarregandoBotao] = useState(false);
    const [produtos, setProdutos] = useState([]);

    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {

        const pegaListagemProdutos = async () => {
            const response = await listaProdutos();
    
            // Ordenando produtos por valor
            setProdutos(response.filter(x => x.active).sort(function(a,b) {
                if(parseFloat(a.valorUnitario) < parseFloat(b.valorUnitario)) return -1;
                if(parseFloat(a.valorUnitario) > parseFloat(b.valorUnitario)) return 1;
                return 0;
            }));
        }

        pegaListagemProdutos();
        dadosUsuario();
        validaUsuarioInscrito();
    },[])

    const dadosUsuario = async () => {
        const usuario = await pegarUsuarioDB(user.uid);

        if(usuario.success){
            if(usuario?.data?.enderecoEntrega?.logradouro){
                setDadosEndereco(usuario.data.enderecoEntrega);
            }

            setNivelAssinaturaUsuario(usuario?.data?.nivelAssinatura ? usuario.data.nivelAssinatura : "");
        }
    }
    
    const encaminhaParaCheckout = async (nomeProduto) => {

        if(Object.keys(dadosEndereco).length == 0){
            setMostraModalCancelar(!mostraModalCancelar);
            setCarregandoBotao(false)
            return;
        }

        // Valida se existe assinatura ativa e manda para o portal para melhorar assinatura.
        if(nivelAssinaturaUsuario){
            const portalUrl = await getPortalUrl()
            window.location = portalUrl;
            setCarregandoBotao(false)
            return
        }

        let priceId = produtos.find(x => x.nomeProduto == nomeProduto)?.idPrice;

        const checkoutUrl = await getCheckoutUrl(priceId);
        
        window.location = checkoutUrl;

        setCarregandoBotao(false)
    }

    const validaUsuarioInscrito = async () => {
        const response = await verificaAssinaturaUsuario();

        if(response){
            setNivelAssinaturaUsuario(response);
        }
    }

    return (
            <div className={styles.divPrincipal}>
                <div className={styles.divEsquerda}>
                    <div>
                        <h2>Bem-vindo!</h2>
                        <h1>{user?.displayName ? user?.displayName : "-"}</h1>
                        <div style={{marginTop: 30, marginBottom: 30}}>
                            <div className={styles.linha}>
                                <h2>Endereço cadastrado</h2>
                                <span onClick={() => setMostraModalCancelar(!mostraModalCancelar)}>
                                    <MdEdit 
                                        color='white'
                                        size={30}
                                    />
                                </span>
                            </div>
                            {dadosEndereco.logradouro ?
                                <h3>{dadosEndereco.logradouro+", "+ dadosEndereco.numero+(dadosEndereco.complemento ?  " - "+dadosEndereco.complemento : "")+" - "+dadosEndereco.bairro+" - "+dadosEndereco.estado+" - "+dadosEndereco.uf+" - "+dadosEndereco.cep}</h3>
                                :
                                <h3>-</h3>
                            }
                        </div>
                        {/* <div>
                            <div className={styles.linha}>
                                <h2>Forma de pagamento</h2>
                                <span onClick={() => {}}>
                                    <MdEdit 
                                        color='white'
                                        size={30}
                                    />
                                </span>
                            </div>
                            <h3>Cartão de crédito</h3>
                            <h3>**** **** **** 3019</h3>
                        </div> */}
                    </div>
                    <BotaoCancelar
                        onClick={() => navigate("../cancelandoAssinatura")}
                        textBotao={"Cancelar assinatura"}
                    />
                </div>
                {mostraModalCancelar &&
                    <Modal
                        onClick={() => {
                            setMostraModalCancelar(!mostraModalCancelar)
                            dadosUsuario();
                        }}
                        idUsuario={user.uid}
                    />
                }
                <div className={styles.divDireita}>
                    <div className={styles.divAlinhamentos}>
                        <BotaoSair
                            onClick={logOut}
                            textoBotao={"Sair"}
                        />
                        <img 
                            className={styles.imagemIcone}
                            src={iconePreto} 
                            alt="Icone"
                        />
                    </div>
                    <div className={styles.divAlinhamentos}>
                        {nivelAssinaturaUsuario != "" &&
                        <>
                            <span className={styles.txtNivel}  style={{alignSelf: 'start', marginLeft: 10}}>Assinatura atual:</span>
                            <h2 className={styles.txtNivel}>{nivelAssinaturaUsuario}</h2>
                        </>
                        }
                        {location?.state &&
                            <div>
                                <span className={styles.txtNivel} style={{fontSize: 20}}>Continuar assinatura de {location?.state.nomeProduto}!</span>
                                <BotaoDourado
                                    onClick={() => { 
                                        setCarregandoBotao(true)
                                        encaminhaParaCheckout(location?.state.nomeProduto);
                                    }}
                                    carregando={carregandoBotao}
                                    textoBotao={"Continuar"}
                                    valor={"R$ "+location?.state.valorUnitario+",00"}
                                />
                            </div>
                        }
                        <span className={styles.txtNivel}  style={{alignSelf: 'start', marginTop: 20, marginLeft: 10}}>{nivelAssinaturaUsuario != "" ? "Atualizar para:" : "Nossos pacotes:"}</span>
                        <div className={styles.coluna}>

                            {location?.state?.nomeProduto != "Sommelier Iniciante" && (nivelAssinaturaUsuario == "") &&
                                <BotaoDourado
                                    onClick={() => { 
                                        setCarregandoBotao(true)
                                        encaminhaParaCheckout("Sommelier Iniciante");
                                    }}
                                    carregando={carregandoBotao}
                                    textoBotao={"Sommelier Iniciante"}
                                    valor={"R$ 100,00"}
                                />
                            }
                            
                            {location?.state?.nomeProduto != "Sommelier Avançado" && (nivelAssinaturaUsuario == "" || nivelAssinaturaUsuario == "Sommelier Iniciante" )&&
                                <BotaoDourado
                                    onClick={() => { 
                                        setCarregandoBotao(true)
                                        encaminhaParaCheckout("Sommelier Avançado");
                                    }}
                                    carregando={carregandoBotao}
                                    textoBotao={"Sommelier Avançado"}
                                    valor={"R$ 200,00"}
                                />
                            }

                            {location?.state?.nomeProduto != "Sommelier Master" && (nivelAssinaturaUsuario != "Sommelier Master") &&
                                <BotaoDourado
                                    onClick={() => { 
                                        setCarregandoBotao(true)
                                        encaminhaParaCheckout("Sommelier Master");
                                    }}
                                    carregando={carregandoBotao}
                                    textoBotao={"Sommelier Master"}
                                    valor={"R$ 400,00"}
                                />
                            }
                        </div>
                        <h5 className={styles.txtNivel} style={{fontWeight: 100, marginTop: 20}}>{
                            nivelAssinaturaUsuario != "" 
                                ? (nivelAssinaturaUsuario == "Sommelier Master" ? "Parabéns, você é amante dos melhores vinhos!" : "")
                                : "Torne-se um apreciador dos melhores vinhos!"}
                        </h5>
                    </div>
                    <div style={{height: 40}}/>
                    {/* <TextoMeses
                        recebidos={"10"}
                        mesesConosco={"10"}
                        mesesConsecutivos={"10"}
                    /> */}
                </div>
            </div>
    );
}

export default Menu;
